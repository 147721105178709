<template>
 <div class="">
  <apexchart
   type="line"
   :options="chartOptions"
   :series="seriesData"
   height="350" />
 </div>
</template>

<script>
export default {
 props: ["dataSeries", "labels"],
 components: {},
 data() {
  return {
   // Data for the series
   seriesData: this.dataSeries,
   //   [
   //   {
   //    name: "Temps d'attente moyen (sec)",
   //    data: [5, 4.8, 5.2, 4.7, 5.1, 5.0, 4.9, 5.3, 5.2, 4.6, 4.9, 5.0],
   //   },
   //  ],
   // Chart options
   chartOptions: {
    chart: {
     id: "monthly-trends",
     toolbar: {
      show: false,
     },
    },
    xaxis: {
     type: "datetime", // Ensures ApexChart treats x-axis data as dates
     categories: this.labels,
     labels: {
      show: true,
      style: {
       fontSize: "12px",
       fontFamily: "Helvetica, Arial, sans-serif",
       fontWeight: 500,
       cssClass: "apexcharts-xaxis-label",
      },
      
     },
     tickAmount: this.labels.length, // Show all labels based on data length
    },
    yaxis: [
     {
      title: {
       text: "Temps (sec)",
      },
      labels: {
       show: true,
       formatter: function (value, timestamp, opts) {
        if (value) {
         if (value > 86400) {
          let quotient = Math.floor(value / 86400);
          let remainder = value % 86400;
          value =
           quotient +
           "." +
           new Date(remainder * 1000).toISOString().substring(11, 19);
         } else {
          value = new Date(value * 1000).toISOString().substring(11, 19);
         }
         return value;
        }
       },
      },
     },
     {
      opposite: true,
      title: {
       text: "Temps (sec)",
      },
      labels: {
       show: true,
       formatter: function (value, timestamp, opts) {
        if (value) {
         if (value > 86400) {
          let quotient = Math.floor(value / 86400);
          let remainder = value % 86400;
          value =
           quotient +
           "." +
           new Date(remainder * 10000).toISOString().substring(11, 19);
         } else {
          value = new Date(value * 10000).toISOString().substring(11, 19);
         }
         return value;
        }
       },
      },
     },
    ],

    stroke: {
     curve: "smooth",
    },
    title: {
     text: "Temps d'attente moyen",
     align: "center",
    },
    legend: {
     position: "top",
    },
    colors: ["#2563eb"],
   },
  };
 },
};
</script>

<style scoped>
/* Custom styles for the chart if needed */
</style>
