<template>
 <div>
  <apexchart
   type="line"
   :options="chartOptions"
   :series="series"
   height="350" />
 </div>
</template>

<script>
export default {
 props: ["dataSeries", "labels"],
 components: {},
 data() {
  return {
   series: this.dataSeries,
   chartOptions: {
    chart: {
     type: "line", // This will be a mixed type chart
     height: 350,
     toolbar: {
      show: false,
     },
    },
    colors: ["#1E7889", "#84cc16", "#1E7889"],
    stroke: {
     width: [0, 4, 4], // Width for column (0) and line (4)
    },
    xaxis: {
     type: "datetime",
     categories: this.labels,
     labels: {
      
      show: true,
      rotate: -75,
      style: {
       colors: [],
       fontSize: "12px",
       fontFamily: "Helvetica, Arial, sans-serif",
       fontWeight: 500,
       cssClass: "apexcharts-xaxis-label",
      },
      
     },
     tickAmount: this.labels.length, // Show all labels based on data length
    },
    yaxis: [
     {
      title: {
       text: "Appels",
      },
     },
     {
      opposite: true,
      title: {
       text: "Taux de réponse",
      },
      labels: {
       show: true,
       formatter: function (value, timestamp, opts) {
        return value + "%";
       },
      },
     },
    ],
    tooltip: {
     shared: true,
     intersect: false,
    },
    plotOptions: {
     bar: {
      columnWidth: "50%",
     },
    },
   },
  };
 },
};
</script>

<style scoped>
/* You can add custom styles here */
</style>
